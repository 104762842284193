.dropdownRef {
  cursor: pointer;
  user-select: none;

  display: flex;
  align-items: center;

  padding: var(--gap-2xs) var(--gap-xs);

  color: var(--form-control-color);

  background-color: var(--form-control-bg-color);
  border-radius: var(--border-radius-s);

  transition: background-color 0.2s ease, border 0.2s ease, color 0.2s ease,
    transform 0.12s ease;
}

.dropdownRef:hover {
  color: var(--form-control-color);
  background-color: var(--form-control-hover-bg-color);
}

.activeOrdersDropdown {
  flex: 0;
  margin-top: calc(-1 * var(--gap-xs));
  margin-bottom: var(--gap-m);
}

.activeOrdersDropdown.mobileVersion {
  margin: var(--gap-m) 0 0;
}

.orderInfoWrapper {
  margin: var(--gap-2xs) var(--gap-xs);
}

.dropDownContent {
  display: flex;
  flex: 1;
  min-width: 0;
}

.dropDownContent > *:not(:first-child) {
  margin-left: var(--gap-2xs);
}

.dropDownContent > *:last-child {
  margin-left: auto;
}

.dropDownItemContent {
  display: flex;
  align-items: center;
}

.dropDownItemContent > *:not(:first-child) {
  margin-left: var(--gap-2xs);
}

.orderName {
  overflow: hidden;
  flex: 1;

  max-width: 80%;

  text-overflow: ellipsis;
  white-space: nowrap;
}

.chevron {
  margin-left: auto;
  color: var(--color-light-graphic-secondary);
  transition: color 0.2s ease, border 0.2s ease, color 0.2s ease,
    transform 0.12s ease;
}

.chevronUp {
  transform: rotate(-0.5turn);
}

.popoverFull {
  width: 100%;
  padding: 0;
  border: none;
}

.popoverFull > div > div,
.popover > div > div {
  padding: 0;
}
