.successMessage {
  max-width: 320px;
  margin-top: var(--gap-s);
  text-align: center;
}

.successText {
  color: var(--color-light-text-secondary);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;
  padding: var(--gap-6xl) 0;
}

.icon {
  margin-bottom: var(--gap-m);
}

.glyph {
  color: var(--color-light-graphic-secondary);
}
