.paramsWrapper {
  display: flex;
  align-items: center;
  margin-right: var(--gap-m);
}

.paramsWrapper > * {
  margin-right: var(--gap-xs);
}

.label {
  margin-right: var(--gap-xs);
  color: var(--color-light-text-secondary);
}

.up {
  color: var(--color-buy);
}

.down {
  color: var(--color-sell);
}

.arrow {
  align-self: flex-end;
}
