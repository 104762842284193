.drawer {
  position: absolute;
  z-index: 1;
  top: 0;

  overflow-y: auto;

  width: 100%;
  height: 100%;
  padding-top: var(--gap-xs);
  padding-right: var(--gap-l);

  background-color: var(--layout-color-background-1);
}
