@import url('@alfalab/core-components/vars/typography.css');

.graphic {
  color: var(--color-light-graphic-tertiary-inverted);
}

.label {
  display: flex;
  align-items: center;
  margin-bottom: var(--gap-2xs);
}

.row {
  display: grid;
  grid-gap: var(--gap-xs);
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.row.small {
  grid-template-columns: minmax(0, 50%) minmax(0, 1fr);
}

.row > div {
  grid-column: span 1;
}

.dropdownRef {
  user-select: none;

  display: flex;
  align-items: center;

  padding: var(--gap-2xs) var(--gap-xs);

  color: var(--form-control-color);

  background-color: var(--form-control-bg-color);
  border-radius: var(--border-radius-s);

  transition: background-color 0.2s ease, border 0.2s ease, color 0.2s ease,
    transform 0.12s ease;
}

.dropdownRef:not(.disabled):hover {
  cursor: pointer;
  color: var(--form-control-color);
  background-color: var(--form-control-hover-bg-color);
}

.dropdownRef.disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.dropDownContent {
  display: flex;
  flex: 1;
  min-width: 0;
}

.dropDownContent > *:not(:first-child) {
  margin-left: var(--gap-2xs);
}

.dropDownContent > *:last-child {
  margin-left: auto;
}

.orderName {
  overflow: hidden;
  flex: 1;

  max-width: 80%;

  text-overflow: ellipsis;
  white-space: nowrap;
}

.chevron {
  margin-left: auto;
  color: var(--color-light-graphic-secondary);
  transition: color 0.2s ease, border 0.2s ease, color 0.2s ease,
    transform 0.12s ease;
}

.chevronUp {
  transform: rotate(-0.5turn);
}

.popper {
  min-width: unset !important;
}

.popperSmall {
  min-width: 120px !important;
}

.dropDownItemContent {
  display: flex;
  align-items: center;
}

.dropDownItemContent > *:not(:first-child) {
  margin-left: var(--gap-2xs);
}

.customInput {
  --form-control-s-min-height: 24px;
}

.customInputField input {
  @mixin paragraph_secondary_large;
}

.customInputField input + div {
  @mixin paragraph_secondary_large;
}

.tooltipTarget {
  display: inline-flex;
}

.tooltipContent {
  display: flex;
  flex-direction: column;
  gap: var(--gap-xs);
  max-width: 180px !important;
}
