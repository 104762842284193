@import url('@alfalab/core-components/vars/typography.css');

.wrapper {
  display: flex;
  flex-direction: column;
  min-width: 580px;
}

.header {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;

  height: var(--header-height);
  padding: var(--gap-s) var(--gap-xs);

  background-color: var(--color-light-bg-tertiary);
}

.footer {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;

  height: var(--header-height);
  padding: var(--gap-xs);

  background-color: var(--color-light-bg-tertiary);
}

.form {
  padding: var(--gap-m) var(--gap-xs) var(--gap-s) var(--gap-xs);
  background-color: var(--color-light-bg-primary);
}

.formRow {
  display: grid;
  grid-template-columns: calc(50% - var(--gap-xs) / 2) calc(
      50% - var(--gap-xs) / 2
    );
  gap: var(--gap-xs);
  justify-content: space-between;

  margin-bottom: var(--gap-xs);
}

.customInput {
  --form-control-s-min-height: 24px;
}

.customInputField > div:first-child > div > div:first-of-type {
  @mixin paragraph_secondary_large;
}

.customInputField input {
  @mixin paragraph_secondary_large;
}

.dropdownRef {
  cursor: pointer;
  user-select: none;

  display: flex;
  flex: 1;
  align-items: center;

  height: 24px;
  padding: var(--gap-xs);

  color: #8d8d93;

  background-color: var(--form-control-bg-color);
  border-radius: var(--border-radius-s);

  transition: background-color 0.2s ease, border 0.2s ease, color 0.2s ease,
    transform 0.12s ease;
}

.dropdownRef:hover {
  color: var(--dark-text-primary);
  background-color: var(--select-option-hover-background);
}

.dropDownContent {
  display: flex;
  flex: 1;
  gap: var(--gap-xs);
  min-width: 0;
}

.chevron {
  margin-left: auto;
  transition: color 0.2s ease, border 0.2s ease, color 0.2s ease,
    transform 0.12s ease;
}

.chevronUp {
  transform: rotate(-0.5turn);
}

.orderName {
  overflow: hidden;
  flex: 1;

  max-width: 55%;

  color: var(--color-light-text-primary);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.plate {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;

  margin-top: var(--gap-m);
  padding: var(--gap-xs);

  color: var(--color-light-graphic-tertiary-inverted);

  border: 1px solid var(--color-light-border-secondary);
  border-radius: var(--border-radius-s);
}

.customButton span {
  @mixin paragraph_secondary_small;
}

.buttonToolbar {
  display: flex;
  flex: 1;
  gap: var(--gap-xl);
  justify-content: flex-end;
}

.cancelButton {
  cursor: pointer;
  display: flex;
  gap: var(--gap-xs);
  transition: var(--button-transition);
}

.cancelButton:hover {
  color: var(--button-ghost-hover-color);
}

.infoTable * {
  border-left: none !important;
  border-radius: 0 !important;
}

.menuTitleWrapper {
  display: flex;
  padding: var(--gap-xs);
  color: var(--color-light-text-secondary);
}

.inputLabel {
  margin-bottom: var(--gap-2xs);
}
