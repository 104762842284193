.docsButtonContainer {
  position: relative;
  padding-left: var(--gap-xl);
}

.docsButtonIcon {
  position: absolute;
  top: var(--gap-3xs);
  left: 0;
  color: var(--color-light-graphic-secondary);
}
