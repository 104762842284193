.modal {
  overflow: auto;

  max-width: 520px;
  max-height: 90%;

  background-color: var(--dark-bg-secondary);
  border-radius: var(--border-radius-m);
}

.content {
  width: 320px;
  background-color: var(--dark-bg-secondary);
}

.header {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.body {
  padding: var(--gap-m);
}

.footer {
  padding: var(--gap-xs);
  text-align: center;
}

.closeActionButtons {
  position: sticky;
  bottom: 0;

  display: flex;
  gap: var(--gap-xs);

  background-color: inherit;
}

.requiredConfirmationTooltip {
  max-width: 256px;
}

.requiredConfirmationIcon {
  vertical-align: middle;
}
