@import url('@alfalab/core-components/vars/typography.css');

.popper:not(.fixSpecificity) {
  display: flex;
  flex-direction: column;

  min-width: 200px;

  background-color: var(--select-option-background);
  box-shadow: var(--select-options-list-box-shadow);
}

.popper > :first-child {
  width: 100%;
}

.popoverChildrenWrapper {
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: var(--gap-xs) 0;
}

.popperViewSelect {
  min-width: 0;
  padding-top: 0;
  border-radius: var(--border-radius-s);
}

.popperViewSelect .popoverChildrenWrapper {
  padding: 0;
}

.header {
  padding: var(--gap-xs) var(--gap-xs) 0;
}

.dropdownTitle {
  cursor: default;
  padding: var(--gap-xs);
}

.dropdownBlock:not(:last-of-type) {
  margin-bottom: var(--gap-xs);
}

.dropdownDivider {
  &.s {
    margin: var(--gap-2xs) 0;
  }

  &.m {
    margin: var(--gap-xs) 0;
  }

  &.l {
    margin: var(--gap-s) 0;
  }
}

/* TODO: https://jira.moscow.alfaintra.net/browse/ADIRWEB-1960 */
.dropdownItem:not(.fixSpecificity) {
  cursor: pointer;
  display: flex;
  gap: var(--gap-xs);
  padding: var(--gap-2xs) var(--gap-xs);

  &.start {
    align-items: start;
  }

  &.center {
    align-items: center;
  }

  &.end {
    align-items: end;
  }

  &:hover {
    background-color: var(--select-option-hover-background);
  }

  &:active {
    background-color: var(--select-option-active-background);
  }
}

.dropdownItem > span {
  flex: 1;
}

.disabled > * {
  cursor: var(--disabled-cursor);
  color: var(--button-ghost-disabled-color) !important;
}

.divider {
  margin: var(--gap-xs) 0;
}

.settingsSelect {
  min-width: 140px;
  padding-top: 0;
}

.settingsHeader {
  @mixin accent_secondary_large;

  position: relative;

  display: flex;
  align-items: center;

  box-sizing: border-box;
  min-height: 32px;
  padding: var(--gap-xs);

  color: #8d8d93;

  background: var(--select-optgroup-background);
}

.settingsOption {
  cursor: pointer;

  position: relative;

  overflow: hidden;
  display: flex;
  flex: 1 1;
  align-items: center;

  box-sizing: border-box;
  padding: var(--gap-s) var(--select-option-right-padding) var(--gap-s)
    var(--select-option-left-padding);

  /* stylelint-disable-next-line property-disallowed-list */
  font-weight: 400;
  color: var(--select-option-color);
  text-overflow: ellipsis;

  background: var(--select-option-background);

  transition: background-color 0.2s ease;
}

.settingsOptionText {
  @mixin paragraph_secondary_large;
}

.settingsDropdown {
  width: 100%;
}

.settingsDropdown [class*='dropdownItem'] {
  flex: none;
  height: 32px;
  margin: var(--gap-2xs) 0;
  padding: var(--gap-xs);
}

.customSwitch {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.customSwitch [class*='switch__label'] {
  @mixin paragraph_secondary_large;
}

.customSwitch [class*='switch__switch'] {
  flex: none;
}
