.grid {
  display: grid;
}

.row {
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: subgrid;

  &:nth-child(odd) {
    background-color: color-mix(
      in srgb,
      var(--color-light-bg-secondary) 60%,
      transparent
    );
  }

  & .cell {
    position: relative;

    overflow: hidden;
    display: flex;
    align-items: center;

    height: 24px;
    padding: 0 var(--gap-xs);

    text-wrap: nowrap;

    &.withoverflow::after {
      content: '';

      position: absolute;
      top: 0;
      right: 0;

      width: 24px;
      height: 100%;

      background: linear-gradient(
        90deg,
        transparent 0%,
        var(--color-light-bg-primary) 24px
      );
    }
  }

  &:nth-child(odd) .cell.withoverflow::after {
    background: linear-gradient(
      90deg,
      transparent 0%,
      var(--shadow-bg-secondary-60) 24px
    );
  }

  &.clickable {
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: var(--color-light-bg-tertiary);

      & .cell.withoverflow::after {
        background: linear-gradient(
          90deg,
          transparent 0%,
          var(--color-light-bg-tertiary) 24px
        );
      }
    }
  }
}
