.container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;

  height: var(--form-control-s-min-height);
  padding: var(--gap-xs);

  border: 1px solid var(--color-light-border-underline);
  border-radius: var(--border-radius-s);
}

.label {
  display: flex;
  gap: var(--gap-2xs);
  align-items: center;
}

.tooltipArrow {
  display: none;
}

.tooltipTarget {
  display: flex;
  align-items: center;
}

.tooltipIcon {
  width: 13px;
  height: 13px;
  color: var(--color-light-graphic-secondary);
}
