.container {
  cursor: pointer;

  display: flex;
  gap: var(--gap-xs);
  align-items: flex-start;

  padding: var(--gap-2xs) var(--gap-xs);

  background: var(--color-light-bg-secondary);
  border-radius: var(--border-radius-s);
}

.container:hover {
  background: var(--color-light-bg-tertiary);
}

.marker {
  display: block;
  flex-shrink: 0;

  width: 6px;
  height: 6px;
  margin-top: 6px;

  background-color: var(--color-light-graphic-accent);
  border-radius: 100%;
}
