@import url('@alfalab/core-components/vars/typography.css');

.sellQtyCell {
  flex-shrink: 0;
  color: var(--color-sell);
  text-align: left;
}

.buyQtyCell {
  flex-shrink: 0;
  color: var(--color-light-text-primary);
  text-align: right;
}

.priceCell {
  cursor: pointer;
  user-select: none;

  min-width: 30%;
  max-width: 30%;
  height: 100%;

  text-align: center !important;
  white-space: nowrap;

  border-right: 1px solid rgb(138 138 142 / 20%);
  border-left: 1px solid rgb(138 138 142 / 20%);
}

.tableWrapper {
  overflow-x: auto;
  display: flex;
  flex: 1;
  flex-direction: column;

  width: 100%;
  min-height: 150px;
}

.tableRow {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 20px;
  min-height: 20px;

  background-color: var(--color-light-bg-primary);
  border-bottom: 1px solid rgb(138 138 142 / 20%);
}

.tableRow:first-of-type {
  border-top: 1px solid rgb(138 138 142 / 20%);
}

.tableRowOdd {
  background-color: var(--color-light-bg-secondary);
}

.cell {
  z-index: 1;
  overflow: hidden;
  padding: 0 var(--gap-xs);
  text-overflow: ellipsis;
}

.dividerRow {
  border-top: 1px solid var(--color-light-border-primary);
}

.bestBidAskRow .cell {
  @mixin accent_component_secondary;
}

.orderCell {
  display: flex;
  gap: var(--gap-xs);
  align-items: center;
  padding: 0 var(--gap-2xs);
}

.spreadRow {
  color: var(--color-light-text-secondary);
}

.selectedRow {
  position: relative;

  &::after {
    content: '';

    position: absolute;
    inset: 0;

    width: 100%;
    height: 100%;
  }
}

.upperSpreadRow {
  --orderbook-histogram-background-color: var(--orderbook-histogram-down);
  --orderbook-histogram-position-right: 0px;
  --orderbook-histogram-position-left: unset;
  --orderbook-count-text-hover: var(--orderbook-count-text-hover-down);

  background-color: var(--orderbook-backgroundcolor-down);
}

.upperSpreadRow.selectedRow::after {
  background-color: var(--orderbook-backgroundcolor-down-selection);
}

.lowerSpreadRow {
  --orderbook-histogram-background-color: var(--orderbook-histogram-up);
  --orderbook-histogram-position-right: unset;
  --orderbook-histogram-position-left: 0px;

  background-color: var(--orderbook-backgroundcolor-up);
}

.lowerSpreadRow.selectedRow::after {
  background-color: var(--orderbook-backgroundcolor-up-selection);
}

.contextRow {
  border-top: 1px solid white;
  border-bottom: 1px solid white;
}

.contextRow:first-of-type {
  border-left: 1px solid white;
}

.contextRow:last-of-type {
  border-right: 1px solid white;
}

.upperSpreadRow .sellQtyCell,
.upperSpreadRow .buyQtyCell,
.upperSpreadRow .orderCell,
.upperSpreadRow .priceCell {
  /* TODO: Надо сделать как-то без --color-sell-inverted */
  color: var(--color-sell-inverted);
}

.tableRowAside {
  position: relative;

  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;

  max-width: 35%;
}

.histogram {
  position: absolute;
  z-index: 0;
  right: var(--orderbook-histogram-position-right);
  left: var(--orderbook-histogram-position-left);

  display: block;

  width: 0;
  height: calc(100% + 2px);

  opacity: 0.4;
  background: var(--orderbook-histogram-background-color);
}

.bondRow .priceCell {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--gap-s);

  min-width: 40%;
  max-width: 40%;
}

.bondRow .tableRowAside {
  max-width: 30%;
}

.bondRow .price {
  text-align: right;
}

.bondRow .yield {
  overflow: hidden;

  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;

  opacity: 0.55;
}
