.tabsHeader {
  position: relative;
  overflow: hidden;
  display: flex;
  max-height: inherit;
}

.auxButton {
  cursor: pointer;

  display: flex;
  flex-shrink: 0;
  justify-content: center;

  padding: var(--gap-2xs) var(--gap-xs);

  color: var(--color-light-graphic-tertiary-inverted) !important;
}

.auxButton:hover {
  color: var(--color-light-graphic-quaternary-inverted) !important;
  background-color: var(--layout-color-surface-0);
  border-radius: var(--border-radius-l) var(--border-radius-l) 0 0 !important;
}

.auxButton:focus {
  color: var(--color-light-graphic-tertiary) !important;
  background-color: var(--button-primary-base-bg-color);
  border-radius: 0 var(--border-radius-l) 0 0 !important;
}

.auxButton.transparent {
  background-color: transparent;
}

.auxCount {
  user-select: none;
  margin-right: var(--gap-2xs);
}

.addButton {
  flex-shrink: 0;
}

.menuItem {
  display: flex;
  gap: var(--gap-xs);
  align-items: center;
}

.closeIcon {
  display: inline-flex;
}

.menuItemLabel {
  display: flex;
  flex: 1;
}
