:root {
  --layout-color-background-accent: #7938e0;
  --layout-color-background-accent-alpha-60: rgb(121 56 224 / 60%);
  --layout-color-background-accent-alpha-40: rgb(121 56 224 / 40%);
  --layout-color-background-0: var(--color-light-border-secondary);
  --layout-color-background-1: var(--color-light-bg-secondary);
  --layout-color-background-2: var(--color-light-bg-primary);
  --layout-color-surface-0: var(--color-light-bg-primary);
  --layout-color-surface-1: var(--color-light-bg-primary);
  --layout-color-surface-2: var(--color-light-bg-primary);
  --layout-color-scrollbar: #b8b9c0;
}
