@import url('@alfalab/core-components/vars/gaps.css');
@import url('@alfalab/core-components/vars/typography.css');

.instrumentSymbolCell {
  display: flex;
  gap: var(--gap-xs);
  align-items: center;
}

.instrumentIconMain {
  width: 18px;
  min-width: 18px;
  height: 18px;
  min-height: 18px;
}

.instrumentIconMainMobile {
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
}

.instrumentIconMainDesktop {
  width: 48px;
  min-width: 48px;
  height: 48px;
  min-height: 48px;
}

.instrumentIconMain img {
  width: 100%;
  height: auto;
  border-radius: 100%;
}

.instrumentIconFallback {
  @mixin paragraph_secondary_small;

  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  width: 18px;
  height: 18px;

  color: #8d8d93;
  text-align: center;

  background: #3a3a3c;
  border-radius: 100%;
}

.instrumentIconFallbackMobile {
  @mixin paragraph_primary_small;

  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
}

.instrumentIconFallbackDesktop {
  @mixin headline-system_small;

  width: 48px;
  min-width: 48px;
  height: 48px;
  min-height: 48px;
}

.displayedName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
