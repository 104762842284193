@import url('@alfalab/core-components/vars/typography.css');

.container {
  overflow: auto;
  max-height: 300px;

  h4 {
    @mixin accent_secondary_large;

    margin: 0 0 var(--gap-2xs);
  }

  h5 {
    @mixin accent_secondary_medium;

    margin: 0 0 var(--gap-2xs);
  }

  a {
    color: inherit;
  }

  ul {
    margin: var(--gap-2xs) 0;
    padding: 0 0 0 var(--gap-s);
    list-style: none;
  }

  ul li {
    position: relative;
  }

  ul li::before {
    content: '–';
    position: absolute;
    top: 0;
    left: var(--gap-s-neg);
  }
}
