/*
 Переопределения стилей компонента Space
*/

[class*='space__center_'] {
  [class*='space__spaceItem_'] {
    /* stylelint-disable-next-line selector-nested-pattern */
    & > svg {
      display: block;
    }
  }
}
