.container {
  position: relative;

  display: flex;
  align-items: center;

  min-height: 24px;
  max-height: 24px;

  background-color: var(--layout-color-surface-0);
  border-top: 2px solid var(--layout-color-background-1);
}

.wrapper {
  display: flex;
  align-items: center;
  padding: var(--gap-3xs) var(--gap-m);
  white-space: nowrap;
}

.wrapper::after {
  content: ' ';

  position: absolute;
  right: 0;

  display: block;

  width: 60px;
  height: 100%;

  background: linear-gradient(
    90deg,
    var(--color-light-specialbg-nulled) -7.81%,
    var(--layout-color-surface-0) 62.5%
  );
}
