.container {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 32px;
  padding: var(--gap-2xs) var(--gap-xl) var(--gap-2xs) var(--gap-xs);

  background-color: var(--color-light-bg-info-muted);
}

.content {
  display: flex;
  align-items: center;
}

.moreButton {
  --size-xxs-height: 24px;

  color: var(--color-light-bg-quaternary-inverted);
  background-color: var(--color-light-specialbg-tertiary-transparent);
}

.text {
  color: var(--color-light-bg-quaternary-inverted);
}

.imageContainer {
  display: flex;
  justify-content: center;
  padding: var(--gap-m) 0;
  background: #f1f1f1;
}

.imageContainerDark {
  background: #27272a;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  padding: var(--gap-l) var(--gap-m);

  background-color: var(--color-light-bg-primary);
}

.list {
  --list-marker-color: var(--color-light-bg-quaternary-inverted);
  --list-item-margin: var(--gap-m);

  color: var(--color-light-bg-quaternary-inverted);
}

.listNoMargin {
  --list-item-margin: var(--gap-3xs);
}

.listBulletContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.bannerIcon {
  position: relative;
  top: -2px;
  margin-right: var(--gap-2xs);
  vertical-align: middle;
}

.submitButton {
  width: 100%;
  margin: var(--gap-xs);
}
