.container {
  --form-control-s-min-height: 24px;

  margin: 0 auto;
}

.assignmentDocumentRow {
  display: flex;
  justify-content: center;
}
