.paramsWrapper {
  display: flex;
  align-items: center;
  margin-right: var(--gap-m);
}

.paramsWrapper > * {
  margin-right: var(--gap-xs);
}

.label {
  margin-right: var(--gap-xs);
  color: var(--color-light-text-secondary);
}
