.infoTableWrapper {
  --item-height: 34px;
  --header-height: 32px;

  width: 100%;
  max-height: calc(var(--item-height) * 7.5 + var(--header-height));
}

.infoTable * {
  border: none !important;
}

.infoTable thead th {
  background-color: var(--color-light-bg-tertiary);
}

.infoTable tbody tr {
  background-color: var(--color-light-bg-secondary);
}

.infoTable tbody tr:nth-child(2n) {
  background-color: var(--color-light-bg-tertiary);
}

.infoTableHeader {
  position: sticky;
  z-index: 1;
  top: 0;
}
