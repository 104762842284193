.container {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 32px;
  padding: var(--gap-2xs) var(--gap-xl) var(--gap-2xs) var(--gap-xs);
}

.actions {
  --size-xxs-height: 24px;

  display: grid;
  grid-auto-flow: column;
  column-gap: 20px;
}

.button {
  color: var(--color-light-bg-quaternary-inverted);
  background-color: var(--color-light-specialbg-tertiary-transparent);
}

.text {
  color: var(--color-light-bg-quaternary-inverted);
}

.imageContainer {
  display: flex;
  justify-content: center;
  background: linear-gradient(0deg, rgb(0 0 0 / 20%) 0%, rgb(0 0 0 / 0%) 100%),
    var(--color-light-bg-tertiary);
}

.body {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  padding: var(--gap-2xl) var(--gap-m);

  background-color: var(--color-light-bg-secondary);
}

.list {
  --list-marker-color: var(--color-light-bg-quaternary-inverted);
  --list-item-margin: var(--gap-l);

  color: var(--color-light-bg-quaternary-inverted);
}

.submitButton {
  margin: 0 var(--gap-m);
}
