.item {
  --main-menu-item-padding-vertical: 4px;
  --main-menu-item-padding-horizontal: 8px;
  --main-menu-item-font-size: 11px;
  --main-menu-item-line-height: 16px;

  cursor: pointer;

  position: relative;

  display: flex;
  align-items: center;

  width: 100%;
  padding: var(--main-menu-item-padding-vertical)
    var(--main-menu-item-padding-horizontal);

  /* stylelint-disable-next-line property-disallowed-list */
  font-size: var(--main-menu-item-font-size);
  /* stylelint-disable-next-line property-disallowed-list */
  line-height: var(--main-menu-item-line-height);
  color: var(--color-light-graphic-tertiary-inverted);

  background: transparent;
  border: 0;
}

.item:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.item:hover,
.active {
  color: var(--color-light-text-primary);
  background: var(--color-light-bg-tertiary);
}

.item:focus-visible {
  background: var(--color-light-bg-tertiary);
  outline: none;
}

.icon,
.rightIcon {
  display: flex;
  align-items: center;

  width: 24px;
  height: 16px;

  color: var(--color-light-graphic-secondary);
}

.icon {
  justify-content: flex-start;
}

.rightIcon {
  justify-content: flex-end;
}

.active .icon,
.active .rightIcon,
.item:hover .icon,
.item:hover .rightIcon {
  color: var(--color-light-graphic-tertiary-inverted);
}

.icon svg,
.rightIcon svg {
  width: 16px;
  height: 16px;
}

.name {
  flex-grow: 1;
  text-align: left;
}
