@import url('@alfalab/core-components/vars/typography.css');

.container {
  width: 320px;
  padding: var(--gap-m);
  background-color: var(--color-light-bg-primary);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--gap-2xs);
}

.closeIcon {
  cursor: pointer;
  color: var(--color-light-graphic-secondary);
  transition: var(--button-transition);
}

.closeIcon:hover {
  color: var(--color-light-text-primary);
}

.buttonToolbar {
  display: flex;
  gap: var(--gap-m);
}

.customButton span {
  @mixin paragraph_secondary_small;
}

.customCancelButton {
  padding-right: var(--gap-m);
  padding-left: var(--gap-s) !important;
  border: 1px solid var(--color-light-border-underline-inverted);
}

.orderTextButton {
  display: flex;
  align-items: center;
  margin: 0;
  color: var(--color-light-graphic-secondary);
}

.orderTextButton > span {
  cursor: pointer;
  margin: 0 var(--gap-xs);
  border-bottom: 1px solid #c5c5c7;
}

.orderTextButton > svg:last-child {
  display: none;
}

.orderInfoRow {
  display: flex;
  margin-top: var(--gap-xs);
}

.orderInfoPreposition {
  display: inline-block;
  width: 20px;
}

.orderBuySellIcon {
  margin-top: var(--gap-2xs);
  margin-right: var(--gap-3xs);
}
