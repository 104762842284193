.drawer {
  position: absolute;
  z-index: 20;
  top: 0;
  left: 0;
  transform: translateX(100%);

  overflow: hidden auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
  height: 100%;
  padding: var(--gap-xs) 0;

  visibility: hidden;
  background-color: var(--color-light-bg-primary);

  transition: transform 0.3s ease;
}

.open {
  transform: translateX(0);
  visibility: unset;
}

.backButton {
  padding: 0 var(--gap-m);
}

.body {
  overflow-y: auto;
  flex: 1;
  padding: 0 var(--gap-m);
}
