.optionContent {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;

  white-space: nowrap;
}

.optionFormula {
  overflow: hidden;
  flex: 1 1 auto;
  text-overflow: ellipsis;
}

.buttonDelete {
  flex-shrink: 0;
}
