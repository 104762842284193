.sidebar {
  position: absolute;
  left: 0;
  transform: translate(0, 0);

  overflow: hidden;

  width: 100%;
  height: 100%;

  visibility: hidden;
  background-color: var(--color-light-bg-primary);

  transition: transform 0.25s ease-in-out;
}

.sidebarOpen {
  transform: translate(-100%, 0);
  visibility: visible;
}

/* Фикс бага с размытием шрифта на кнопках внутри sidebar */
.sidebar [class*='button__component'] {
  will-change: initial;
}

.inner {
  position: relative;
  overflow: hidden auto;
  height: 100%;
}

.head {
  position: sticky;
  z-index: 1;
  top: 0;

  display: flex;
  justify-content: space-between;

  padding: var(--gap-l) var(--gap-m) var(--gap-xl);

  background-color: var(--color-light-bg-primary);
}

.headAddon {
  display: flex;
  align-items: center;
}

.content {
  height: calc(100% - var(--gap-l) - var(--gap-xl) - var(--gap-xl));
  padding: 0 var(--gap-m) var(--gap-m);
}

.back {
  --button-ghost-base-color: var(--color-light-text-secondary);
}

.backCircle {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;

  color: var(--color-light-graphic-tertiary-inverted);

  background-color: var(--button-secondary-base-bg-color);
  border-radius: 100%;
}

.back:hover .backCircle {
  background-color: var(--button-secondary-hover-bg-color);
}
