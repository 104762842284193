.container {
  position: relative;
  max-width: 160px;
}

.optionsList {
  min-width: 444px;
  border-bottom-right-radius: var(--border-radius-s);
  border-bottom-left-radius: var(--border-radius-s);
}

.formulaOptions {
  clip-path: inset(0 -30px -30px -30px);
}

.formulaStyle:has(input:focus) {
  z-index: 50;
  width: 444px;
  max-width: none;
  box-shadow: var(--select-options-list-box-shadow);

  /* Для того чтобы остальные элементы не сдвигались */
  &::before {
    content: '';

    display: block;

    width: 160px;
    height: 0;

    visibility: hidden;
  }

  .input {
    padding: var(--gap-2xs) 0;

    background-color: var(--select-option-background);
    clip-path: inset(-30px -30px 0 -30px);
    border-top-left-radius: var(--border-radius-s);
    border-top-right-radius: var(--border-radius-s);
  }
}

.customInput {
  --form-control-s-min-height: 24px;
  --form-control-paddings: 0 var(--gap-xs);
}

.customInput [class*='form-control__leftAddons'] {
  padding-left: var(--gap-xs);
}

.customInput [class*='form-control__rightAddons'] {
  padding-right: var(--gap-xs);
}

.customInput input {
  background-color: transparent !important;
}

.dropdown {
  --select-options-list-box-shadow: none;
  --shadow-m: none;
}
