.select {
  --form-control-s-min-height: 24px;
  --form-control-paddings: 0 var(--gap-xs);
}

.value {
  display: flex;
  gap: var(--gap-2xs);
  align-items: center;
}
