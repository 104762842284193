.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 360px;
  margin: 0 auto;

  text-align: center;
}
