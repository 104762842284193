html[data-direction-colors='greenAndRed'] {
  --color-buy: var(--color-light-text-positive);
  --color-sell: var(--color-light-graphic-negative);
  --color-buy-inverted: var(--color-light-text-primary);
  --color-sell-inverted: var(--color-light-text-primary);
  --chartiq-color-candle-up: #2fc26e;
  --chartiq-color-candle-down: #ef3124;
  --chartiq-color-open-order-marker-up: #13a463;
  --chartiq-color-open-order-marker-up-faded: #042117;
  --chartiq-color-open-order-marker-down: #ef3124;
  --chartiq-color-open-order-marker-down-faded: #35110d;
  --orderbook-histogram-up: #2fc26e;
  --orderbook-backgroundcolor-up: var(--color-light-bg-positive-muted);
  --orderbook-backgroundcolor-up-selection: rgb(47 194 110 / 30%);
  --orderbook-histogram-down: #ef3124;
  --orderbook-backgroundcolor-down: var(--color-light-bg-negative-muted);
  --orderbook-backgroundcolor-down-selection: rgb(239 49 36 / 30%);
  --chart-marker-text-color-down: var(--color-static-text-primary-light);
}

html[data-direction-colors='blueAndRed'] {
  --color-buy: var(--color-light-text-link);
  --color-sell: var(--color-light-graphic-negative);
  --color-buy-inverted: var(--color-light-text-primary);
  --color-sell-inverted: var(--color-light-text-primary);
  --chartiq-color-candle-up: #0072ef;
  --chartiq-color-candle-down: #d91d0b;
  --chartiq-color-open-order-marker-up: #0072ef;
  --chartiq-color-open-order-marker-up-faded: #001c3c;
  --chartiq-color-open-order-marker-down: #d91d0b;
  --chartiq-color-open-order-marker-down-faded: #35110d;
  --orderbook-histogram-up: #0072ef;
  --orderbook-backgroundcolor-up: var(--color-light-bg-info);
  --orderbook-backgroundcolor-up-selection: rgb(0 114 239 / 30%);
  --orderbook-histogram-down: #d91d0b;
  --orderbook-backgroundcolor-down: var(--color-light-bg-negative-muted);
  --orderbook-backgroundcolor-down-selection: rgb(217 29 11 / 30%);
  --chart-marker-text-color-down: var(--color-static-text-primary-light);
}

html[data-direction-colors='blueAndWhite'] {
  --color-buy: var(--color-light-text-link);
  --color-sell: var(--color-light-text-primary);
  --color-buy-inverted: var(--color-light-text-primary);
  --color-sell-inverted: var(--color-light-text-primary-inverted);
  --chartiq-color-candle-up: #0072ef;
  --chartiq-color-candle-down: var(--color-light-graphic-primary);
  --chartiq-color-open-order-marker-up: #0072ef;
  --chartiq-color-open-order-marker-up-faded: #001c3c;
  --chartiq-color-open-order-marker-down: var(
    --color-light-graphic-tertiary-inverted
  );
  --orderbook-histogram-up: #0072ef;
  --orderbook-backgroundcolor-up: var(--color-light-bg-info);
  --orderbook-backgroundcolor-up-selection: rgb(0 114 239 / 30%);
  --orderbook-histogram-down: var(--orderbook-histogram-blue-white-down);
  --orderbook-backgroundcolor-down: var(--color-light-bg-quaternary-inverted);
  --orderbook-backgroundcolor-down-selection: var(
    --orderbook-histogram-blue-white-down-alpha-30
  );
  --chartiq-color-open-order-marker-down-faded: #2c2c2e;
  --chart-marker-text-color-down: var(--color-light-text-primary-inverted);
  --orderbook-count-text-hover-down: var(--color-light-text-primary);
}
