.orderDimension {
  display: flex;
  flex-direction: column;
}

.dimensionSelector {
  margin-top: var(--gap-s);
}

.orderDimensionSelectTextContainer {
  margin-top: var(--gap-xs);
}

.selectContainer {
  width: 236px;
  margin-top: var(--gap-l);
}
