@import url('@alfalab/core-components/vars/typography.css');

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
}

.infoContainer {
  z-index: 1;

  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  min-height: 65px;
  margin-top: var(--gap-2xl-neg);

  background-color: var(--layout-color-background-1);
}

.infoContainerButtonsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.infoContainerDescription {
  text-align: center;
}

.infoContainerButton {
  @mixin paragraph_secondary_large;

  width: 148px;
  min-height: 24px;
  max-height: 24px;
}

.infoContainerButtonSecondary {
  width: 290px;
}

.modalWrapper {
  color: inherit;
}
