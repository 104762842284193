.draggableHeader {
  cursor: grab;
  padding: 0 var(--gap-m);
}

.draggableContainer {
  position: absolute;
  z-index: 20;
  top: 0;
  left: 0;
}

.backdrop {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  background: var(--backdrop-visible-background);
}

.container {
  position: absolute;
  z-index: 101;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;

  max-width: 95vw;

  background-color: var(--color-light-bg-primary);
  box-shadow: var(--shadow-xl);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 32px;
  padding: 0 var(--gap-xs);

  background: var(--color-light-bg-secondary);
}

.footer {
  display: flex;
  justify-content: end;
  padding: var(--gap-xs) var(--gap-m);
  background: var(--color-light-bg-secondary);
}

.content {
  overflow: hidden;
  display: flex;
  flex: 1 1;
  background-color: var(--color-light-bg-secondary);
}

.body {
  position: relative;
  z-index: 10;

  overflow: hidden;
  flex-grow: 1;

  background-color: var(--color-light-bg-primary);
}

.inner {
  overflow-y: auto;
  height: 100%;
  max-height: calc(95vh - 50px);
  padding: var(--gap-m);
}

.inner.padd_xs {
  padding-right: var(--gap-xs);
  padding-left: var(--gap-xs);
}

.inner.padd_s {
  padding-right: var(--gap-s);
  padding-left: var(--gap-s);
}

.inner.padd_m {
  padding-right: var(--gap-m);
  padding-left: var(--gap-m);
}

.inner.padd_l {
  padding-right: var(--gap-l);
  padding-left: var(--gap-l);
}

.inner.padd_xl {
  padding-right: var(--gap-xl);
  padding-left: var(--gap-xl);
}

.inner.padd_2xl {
  padding-right: var(--gap-2xl);
  padding-left: var(--gap-2xl);
}

.sidebar {
  flex-shrink: 0;
  max-width: 29.5%;
}

.sidebar + .body {
  box-shadow: -1px 0 0 var(--color-light-bg-tertiary);
}

.drawer {
  position: absolute;
  z-index: 50;
  top: 0;
  left: 100%;

  width: 100%;
  height: 100%;

  background-color: rebeccapurple;
}
