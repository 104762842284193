.layoutWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.layout {
  display: flex;
  flex: 1;
  height: 100%;
  background-color: var(--layout-color-background-0);
}

/* переопределяем библиотечные стили */
/* stylelint-disable-next-line */
.layout :global(.os-theme-light) {
  --os-handle-bg-hover: var(--layout-color-scrollbar);
  --os-handle-bg: var(--layout-color-scrollbar);
  --os-handle-bg-active: var(--layout-color-scrollbar);
}

/* переопределяем дефолт библиотеки, чтобы можно было увидеть скроллбар на ховер */
/* stylelint-disable-next-line */
.layout :global(.os-scrollbar) {
  visibility: visible !important;
  transition: opacity 0.5s ease-in-out;
}

/* переопределяем библиотечные стили */
/* stylelint-disable-next-line */
.layout :global(.os-scrollbar):hover {
  opacity: 1;
}

.scroll_hidden {
  overflow: hidden;
}

.colision {
  position: absolute;
  opacity: 0;
}

.leftColision {
  border-right: 1px dashed var(--layout-color-background-accent);
}

.rightColision {
  border-left: 1px dashed var(--layout-color-background-accent);
}

.topColision {
  border-bottom: 1px dashed var(--layout-color-background-accent);
}

.bottomColision {
  border-top: 1px dashed var(--layout-color-background-accent);
}

.layout::after {
  content: '';

  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  visibility: hidden;
  opacity: 0;
  background-color: #353c00;
  border: 2px solid var(--color-light-graphic-link);

  transition: all 300ms ease-in-out;
}

.droppable.layout::after {
  visibility: visible;
  opacity: 0.4;
}
