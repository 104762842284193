.back {
  --button-ghost-base-color: var(--color-light-text-secondary);
}

.backCircle {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;

  color: var(--color-light-graphic-tertiary-inverted);

  background-color: var(--button-secondary-base-bg-color);
  border-radius: 100%;
}

.back:hover .backCircle {
  background-color: var(--button-secondary-hover-bg-color);
}
