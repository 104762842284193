.container {
  display: flex;
  flex-direction: column;
  padding: var(--gap-xl) var(--gap-l) var(--gap-l) var(--gap-l);
}

.container > * + * {
  margin-top: var(--gap-2xl);
}

.desktop .displayInstrumentTypeSelectTextContainer {
  margin-top: var(--gap-xs);
}

.displayInstrumentTypeSelect {
  margin-top: var(--gap-s);
}

.displayInstrumentTypeSelectText {
  margin-top: var(--gap-xs);
}

.desktop .selectContainer {
  width: 236px;
  margin-top: var(--gap-l);
}

.confirmContainer {
  background-color: var(--color-light-bg-secondary);
  border-radius: var(--border-radius-l);
}

.confirmInputContainer {
  padding: var(--gap-s) var(--gap-m);
}

.confirmMessageContainer {
  position: relative;
  padding: var(--gap-s) var(--gap-m);
  padding-left: 56px;
}

.confirmInfoIcon {
  position: absolute;
  top: var(--gap-m);
  left: var(--gap-xl);
}
