@import url('@alfalab/core-components/vars/typography.css');

.confirmText {
  display: contents;
  color: var(--color-light-text-secondary);
}

.confirmTextTitle {
  margin-left: var(--gap-2xs);
  color: var(--color-light-text-secondary);
}

.closeParams {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  color: var(--color-light-text-primary);
}

.quantity {
  color: var(--color-light-text-secondary);
}

.closeActionButtons {
  position: sticky;
  bottom: 0;

  display: flex;
  gap: var(--gap-xs);

  padding: var(--gap-xs) 0;

  background-color: inherit;
}

.instructions {
  display: contents;
}

.instructions > svg {
  margin-right: var(--gap-2xs);
}

.customSwitch {
  margin-top: 10px;
  margin-bottom: 10px;
}

.orderTextButton {
  display: flex;
  align-items: center;
  margin: var(--gap-m) 0 var(--gap-xs) 0;
  color: var(--dark-text-primary);
}

.orderTextButton > span {
  cursor: pointer;
  flex: 1;
  margin: 0 var(--gap-m);
  text-decoration: underline;
}

.orderTextButton.mobileVersion > span {
  flex: 1;
  margin: 0 var(--gap-m);
  text-decoration: none;
}

.orderTextButton > svg:last-child {
  display: none;
}

.orderTextButton.mobileVersion > svg {
  display: block;
}

.orderTextTitle {
  @mixin paragraph_primary_medium;

  display: block;
  margin-left: var(--gap-4xl-neg);
  color: var(--color-light-text-primary-inverted);
  text-align: center;
}

.orderTextSheet {
  height: calc(var(--vh, 1vh) * 100) !important;
  max-height: calc(var(--vh, 1vh) * 100) !important;
  background-color: var(--dark-bg-primary);
  border-radius: 0;
}

.orderTextSheetContent {
  position: relative;

  overflow: auto;

  height: calc(100% - 40px - var(--gap-m));

  color: #c5c5c7;

  background-color: var(--dark-bg-primary);
}

.orderTextSheetHeader {
  padding: 0;
  background-color: var(--dark-bg-primary);
}

.orderTextSheetFooter {
  padding: var(--gap-s) var(--gap-l);
  padding-bottom: max(env(safe-area-inset-bottom), var(--gap-s));
  background-color: var(--dark-bg-primary);
}

.orderTextSheetSubmitButton {
  @mixin paragraph_primary_small;
}

.orderText {
  display: flex;
  flex: 1;
  padding-bottom: var(--gap-m);
  white-space: pre-line;
}

@media all and (display-mode: standalone) {
  .orderTextSheet {
    height: calc(
      var(--vh, 1vh) * 100 - env(safe-area-inset-bottom) - 14px
    ) !important;
    max-height: calc(
      var(--vh, 1vh) * 100 - env(safe-area-inset-bottom) - 14px
    ) !important;
    background-color: var(--dark-bg-primary);
    border-radius: 0;
  }
}

.orderTextErrorWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: rgb(235 235 245 / 60%);
  text-align: center;
}

.orderTextErrorIcon {
  margin-bottom: var(--gap-m);
}

.orderTextErrorTitle {
  @mixin paragraph_primary_large;

  margin-bottom: var(--gap-2xs);
  color: #c5c5c7;
}

.orderTextSectionTitle {
  @mixin paragraph_primary_medium;

  padding-bottom: var(--gap-2xs);
  color: var(--color-light-text-primary-inverted);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--gap-m);
}

.closeIcon {
  cursor: pointer;
  color: #8d8d93;
  transition: var(--button-transition);
}

.closeIcon:hover {
  color: var(--dark-text-primary);
}

.sum {
  margin-top: var(--gap-xs);
}

.desktopModalContent {
  padding: var(--gap-m);
  background-color: var(--dark-bg-secondary);
}

.desktopModal {
  overflow: auto;
  max-width: 520px;
  max-height: 90%;
  background-color: var(--dark-bg-secondary);
}
