.tooltipIcon {
  width: 14px;
  height: 14px;
  color: var(--color-light-graphic-secondary);
  vertical-align: bottom;
}

.tooltipContent {
  max-width: 360px;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: var(--gap-s);
}

.spinner {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: var(--gap-6xl) 0;
}

.testsListContainer {
  margin-top: var(--gap-2xl);
  margin-right: 44px;
}

.testListItem {
  cursor: pointer;

  display: flex;
  align-items: center;

  height: 96px;
  margin-bottom: var(--gap-m);
  padding: var(--gap-m) var(--gap-xl);

  background-color: var(--color-light-bg-secondary);
  border-radius: 5px;

  transition: all ease-in-out 0.15s;
}

.testListItem:hover {
  background-color: var(--color-light-bg-tertiary);
}

.testDescription {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: var(--gap-m);
}

.testStatus {
  flex-shrink: 0;
  margin-left: auto;
}

.testIcon {
  flex-shrink: 0;

  width: 64px;
  height: 64px;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.checkmarkIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
}
