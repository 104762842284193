@import url('@alfalab/core-components/vars/typography.css');

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: var(--gap-2xs);
}

.wrapper + .wrapper {
  margin-top: 22px;
}

.icon {
  display: flex;
  place-content: center center;
  width: 24px;
  max-width: 24px;
}

.currency {
  @mixin paragraph_primary_small;

  margin-left: var(--gap-2xs);
  color: var(--color-light-text-secondary);
}

.subtitleRight {
  @mixin paragraph_secondary_medium;

  color: var(--color-light-text-secondary);
  text-align: right;
  white-space: nowrap;
}
