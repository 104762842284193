/* stylelint-disable */
/* это копипаста из либы*/
.flexlayout__layout {
  position: relative;
  overflow: hidden;
  flex: 1;
  border-top: 4px solid var(--layout-color-background-0);
}

.flexlayout__splitter {
  background-color: var(--layout-color-background-0);

  &:hover {
    @media (hover: hover) {
      background-color: var(--color-light-border-secondary);
    }
  }
}

.flexlayout__splitter_border {
  z-index: 10;
}

.flexlayout__splitter_drag {
  z-index: 1000;
  background-color: var(--color-light-border-primary);
}

.flexlayout__splitter_extra {
  background-color: transparent;
}

.flexlayout__outline_rect {
  pointer-events: none;

  position: absolute;
  z-index: 1000;

  box-sizing: border-box;

  opacity: 0.4;
  background-color: var(--color-light-bg-info);
  border: 2px solid var(--color-light-graphic-link);
}

.flexlayout__outline_rect_edge {
  pointer-events: none;

  z-index: 1000;

  box-sizing: border-box;

  opacity: 0.4;
  background-color: #001c3c;
  border: 2px solid var(--color-light-graphic-link);
}

.flexlayout__edge_rect {
  pointer-events: none;

  position: absolute;
  z-index: 1000;

  background-color: var(--dark-text-tertiary-inverted);
  border-radius: unset !important;
}

.flexlayout__drag_rect {
  cursor: grabbing;

  position: absolute;
  z-index: 1000;

  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;

  box-sizing: border-box;
  height: 24px;
  padding: var(--gap-2xs) var(--gap-xs);

  word-wrap: break-word;

  background-color: var(--color-light-bg-secondary);
  border-radius: var(--border-radius-l) var(--border-radius-l) 0 0;
}

.flexlayout__tabset {
  overflow: hidden;
  box-sizing: border-box;
}

.flexlayout__tabset_header {
  position: absolute;
  right: 0;
  left: 0;

  display: flex;
  align-items: center;

  box-sizing: border-box;
  padding: 3px 3px 3px 5px;

  color: var(--color-light-text-primary);

  background-color: var(--color-light-bg-primary);
  border-bottom: 1px solid #262626;
  box-shadow: inset 0 0 3px 0 rgb(136 136 136 / 54%);
}

.flexlayout__tabset_header_content {
  flex-grow: 1;
}

.flexlayout__tabset_tabbar_outer {
  position: absolute;
  right: 0;
  left: 0;

  overflow: hidden;
  display: flex;

  box-sizing: border-box;
}

.flexlayout__tabset_tabbar_inner {
  position: relative;

  overflow: hidden;
  display: flex;
  flex-grow: 1;

  box-sizing: border-box;
}

.flexlayout__tabset_tabbar_inner_tab_container {
  position: absolute;
  top: 0;
  bottom: 0;

  display: flex;

  box-sizing: border-box;
  width: 10000px;
}

.flexlayout__tab {
  position: absolute;
  box-sizing: border-box;
  color: var(--color-light-text-primary);
}

.flexlayout__tab_button {
  cursor: grab;

  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  /* zIndex 0 нужен при корректном отображении при drag n drop */
  z-index: 0;

  box-sizing: border-box;
  min-width: 60px;
  padding: var(--gap-2xs) var(--gap-3xs) var(--gap-2xs) var(--gap-2xs);

  border-radius: var(--border-radius-l) var(--border-radius-l) 0 0;
}

.flexlayout__tab_button--selected {
  margin-right: var(--gap-xs);
  color: var(--color-light-graphic-quaternary-inverted);
  background-color: var(--layout-color-surface-0);
}

@media (hover: hover) {
  .flexlayout__tab_button:not(.flexlayout__tab_button--selected):hover {
    color: var(--color-light-graphic-quaternary-inverted);
    background-color: var(--layout-color-surface-0);
  }

  .flexlayout__tab_button--selected:hover {
    color: var(--color-light-text-primary);
  }
}

.flexlayout__tab_button--unselected {
  position: relative;
  color: var(--color-light-graphic-tertiary-inverted);
}

.flexlayout__tab_button_leading {
  display: inline-flex;
  margin-right: var(--gap-2xs);
  margin-left: var(--gap-2xs-neg);
}

.flexlayout__tab_button_content {
  display: inline-block;
  font-weight: bold;
}

.flexlayout__tab_button_textbox {
  color: green;
  background-color: #262626;
  border: none;
}

.flexlayout__tab_button_textbox:focus {
  outline: none;
}

.flexlayout__tab_button_trailing {
  display: inline-flex;
  cursor: pointer;
  margin-left: var(--gap-2xs);
  color: var(--color-light-graphic-secondary);
  visibility: hidden;

  .flexlayout__tab_button:hover &,
  .flexlayout__tab_button--selected & {
    visibility: unset;
  }

  &:hover {
    color: var(--icon-button-secondary-hover-color);
  }
}

.flexlayout__tab_button_stamp {
  display: flex;
  align-items: center;
}

.flexlayout__tab_button_overflow {
  cursor: pointer;

  height: 100%;
  padding-right: var(--gap-xl);
  padding-left: var(--gap-xs);

  font-size: inherit;
  font-weight: bold;
  color: var(--color-light-graphic-tertiary-inverted);

  background: transparent url('@terminal/common/icons/chevron-down_m.svg')
    no-repeat right 8px center;
  background-size: 12px;
  border: none;
  border-radius: var(--border-radius-l) var(--border-radius-l) 0 0;
}

.flexlayout__tab_button_overflow:hover {
  color: var(--color-light-text-primary);
  background-color: var(--layout-color-surface-0);
}

.flexlayout__tab_toolbar {
  display: flex;
  align-items: center;
}

.flexlayout__tab_toolbar--single-tab .flexlayout__tab_toolbar_button {
  display: none;
}

.flexlayout__tab_toolbar_button {
  min-width: 20px;
  min-height: 20px;
  border: none;
  outline: none;
}

.flexlayout__tab_toolbar_button-min {
  display: none;
}

.flexlayout__tab_toolbar_button-max {
  display: none;
}

.flexlayout__tab_toolbar_button-float {
  background: transparent url('../images/popout.png') no-repeat center;
}

.flexlayout__tab_toolbar_sticky_buttons_container {
  display: flex;
  align-items: center;
  margin-left: var(--gap-2xs);
}

.flexlayout__tab_button--selected
  + .flexlayout__tabset_tab_divider
  + .flexlayout__tab_toolbar_sticky_buttons_container {
  margin-left: var(--gap-2xs-neg);
}

.flexlayout__tab_floating {
  position: absolute;

  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;

  color: var(--color-light-text-primary);

  background-color: black;
}

.flexlayout__tab_floating_inner {
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flexlayout__tab_floating_inner div {
  margin-bottom: 5px;
  text-align: center;
}

.flexlayout__tab_floating_inner div a {
  color: royalblue;
}

.flexlayout__border {
  overflow: hidden;
  display: flex;
  box-sizing: border-box;
  background-color: var(--color-light-bg-primary);
}

.flexlayout__border_top {
  align-items: center;
  border-bottom: 1px solid #262626;
}

.flexlayout__border_bottom {
  align-items: center;
  border-top: 1px solid #262626;
}

.flexlayout__border_left {
  flex-direction: column;
  align-content: center;
  border-right: 1px solid #262626;
}

.flexlayout__border_right {
  flex-direction: column;
  align-content: center;
  border-left: 1px solid #262626;
}

.flexlayout__border_inner {
  position: relative;

  overflow: hidden;
  display: flex;
  flex-grow: 1;

  box-sizing: border-box;
}

.flexlayout__border_inner_tab_container {
  position: absolute;
  top: 0;
  bottom: 0;

  display: flex;

  box-sizing: border-box;
  width: 10000px;

  white-space: nowrap;
}

.flexlayout__border_inner_tab_container_right {
  transform-origin: top left;
  transform: rotate(90deg);
}

.flexlayout__border_inner_tab_container_left {
  transform-origin: top right;
  transform: rotate(-90deg);
  flex-direction: row-reverse;
}

.flexlayout__border_button {
  cursor: pointer;

  display: flex;
  align-items: center;

  box-sizing: border-box;
  margin: var(--gap-3xs);
  padding: 3px var(--gap-xs);

  white-space: nowrap;

  background-color: red;
  border-radius: 3px;
  box-shadow: inset 0 0 5px rgb(0 0 0 / 15%);
}

.flexlayout__border_button--selected {
  color: var(--color-light-text-primary);
  background-color: #262626;
}

@media (hover: hover) {
  .flexlayout__border_button:hover {
    color: var(--color-light-text-primary);
    background-color: #262626;
  }
}

.flexlayout__border_button--unselected {
  color: gray;
}

.flexlayout__border_button_leading {
  display: inline;
}

.flexlayout__border_button_content {
  display: inline-block;
}

.flexlayout__border_button_trailing {
  display: inline-block;
  min-width: 8px;
  min-height: 8px;
  margin-left: var(--gap-xs);
}

@media (pointer: coarse) {
  .flexlayout__border_button_trailing {
    min-width: 20px;
    min-height: 20px;
  }
}

.flexlayout__border_toolbar {
  display: flex;
  align-items: center;
}

.flexlayout__border_toolbar_left {
  flex-direction: column;
}

.flexlayout__border_toolbar_right {
  flex-direction: column;
}

.flexlayout__border_toolbar_button {
  min-width: 20px;
  min-height: 20px;
  border: none;
  outline: none;
}

.flexlayout__border_toolbar_button-float {
  background: transparent url('../images/popout.png') no-repeat center;
}

.flexlayout__border_toolbar_button_overflow {
  padding-left: var(--gap-s);

  font-size: inherit;
  color: gray;

  background: transparent url('../images/more2.png') no-repeat left;
  border: none;
}

.flexlayout__border_toolbar_button_overflow_top,
.flexlayout__border_toolbar_button_overflow_bottom {
  margin-left: 10px;
}

.flexlayout__border_toolbar_button_overflow_right,
.flexlayout__border_toolbar_button_overflow_left {
  margin-top: 5px;
  padding-right: 0;
}

.flexlayout__popup_menu_item {
  cursor: pointer;
  padding: var(--gap-2xs) var(--select-option-right-padding) var(--gap-2xs)
    var(--select-option-left-padding);
  white-space: nowrap;
}

@media (hover: hover) {
  .flexlayout__popup_menu_item:hover {
    background-color: var(--select-option-hover-background);
  }
}

.flexlayout__popup_menu_container {
  position: absolute;
  z-index: 1000;
  transform: translateX(-8px) translateY(20px);

  overflow: auto;

  min-width: 100px;
  max-height: 50%;
  padding: var(--gap-xs) 0;

  color: var(--color-light-text-primary);

  background-color: var(--select-option-background);
  border-radius: 3px;
  box-shadow: var(--select-options-list-box-shadow);
}

.flexlayout__floating_window _body {
  height: 100%;
}

.flexlayout__floating_window_content {
  position: absolute;
  inset: 0;
}

.flexlayout__floating_window_tab {
  position: absolute;
  inset: 0;

  overflow: auto;

  box-sizing: border-box;

  color: var(--color-light-text-primary);

  background-color: black;
}

.flexlayout__error_boundary_container {
  position: absolute;
  inset: 0;

  display: flex;
  justify-content: center;
}

.flexlayout__error_boundary_content {
  display: flex;
  align-items: center;
}

.flexlayout__tabset_sizer {
  padding-top: 5px;
  padding-bottom: 3px;
}

.flexlayout__tabset_header_sizer {
  padding-top: 3px;
  padding-bottom: 3px;
}

.flexlayout__border_sizer {
  padding-top: 6px;
  padding-bottom: 5px;
}

.flexlayout__add_button {
  cursor: pointer;

  width: 24px;
  height: 24px;
  margin-left: 5px;

  background: transparent url('../images/add.svg') no-repeat center;
}

/* Стилизация для scrollbar */
.flexlayout__tab {
  scrollbar-color: var(--color-light-bg-secondary) var(--color-light-bg-primary);
  scrollbar-width: thin;
  overflow: hidden auto;
}

/* Контекстное меню добавления виджетов */
.flexlayout__add_context_wrapper {
  position: fixed;
  z-index: 2;
  overflow: auto;
}
