.popover {
  width: 300px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}

.submit {
  margin-left: auto;
}
