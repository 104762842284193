.spinner {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 100px auto;
}

.container {
  --button-ghost-base-color: var(--color-light-text-tertiary-inverted);

  display: flex;
  flex-direction: column;
  margin: 0 var(--gap-xs);
}

.title {
  margin-bottom: var(--gap-xs);
}

.progress {
  margin: var(--gap-xl) 0 0;
}

.progressLabel {
  margin-bottom: var(--gap-2xs);
}

.question {
  margin: var(--gap-l) 0 0;
}

.questionTitle {
  margin: 0 0 var(--gap-l);
}

.answerItem {
  display: flex;
  gap: var(--gap-xs);
  align-items: flex-start;
  margin: 0 0 var(--gap-l);
}

.answerText {
  padding-top: var(--gap-2xs);
}

.tooltipContent {
  max-width: 360px;
}

.hideArrow {
  display: none;
}

.next {
  max-width: 239px;
  margin-top: var(--gap-2xs);
}

.nextArrow {
  transform: rotateY(180deg);
}
