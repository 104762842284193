.filters {
  display: flex;
  flex: 1;
  gap: var(--gap-xs);
  white-space: nowrap;
}

.accountsSelectButton {
  cursor: pointer;

  display: flex;
  align-items: center;

  box-sizing: border-box;
  width: 160px;
  height: 24px;
  padding: var(--gap-2xs) var(--gap-xs);

  color: var(--color-light-graphic-tertiary-inverted);

  background-color: var(--form-control-bg-color);
  border-radius: var(--border-radius-s);

  transition: background-color 0.2s ease, border 0.2s ease, color 0.2s ease;
}

.accountsSelectButton:hover {
  background-color: var(--form-control-hover-bg-color);
}

.accountsSelectButtonSmall {
  width: unset;
}

.accountsSelectButtonSmall .accountsSelectButtonIcon {
  margin-right: var(--gap-xs);
}

.accountName {
  margin-left: var(--gap-xs);
}

.chevron {
  margin-left: auto;
  color: var(--color-light-graphic-secondary);
  transition: color 0.2s ease, border 0.2s ease, color 0.2s ease,
    transform 0.12s ease;
}

.chevronUp {
  transform: rotate(-0.5turn);
}
