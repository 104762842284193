.formulaInput:has(input:focus) {
  max-width: none;
  background-color: transparent !important;
  box-shadow: none !important;
}

.marketBoardLabel {
  pointer-events: none;

  position: absolute;
  top: 50%;
  transform: translate(18px, -50%);

  overflow: hidden;

  /* 52px = Ширина лого + ширина иконки справа */
  max-width: calc(100% - 52px);
  padding: var(--form-control-paddings);

  color: #8d8d93;
  white-space: nowrap;
}

.marketBoardPadding {
  margin-right: 0.5em;
  visibility: hidden;
}

.formulaActions {
  display: flex;
  gap: var(--gap-xs);
  align-items: center;
  justify-content: space-between;

  height: 16px;
  padding: var(--gap-3xs) var(--gap-2xs);

  background-color: var(--color-light-bg-quaternary);
  border-radius: var(--border-radius-xs);
}

.rightAddons {
  display: flex;
  flex-direction: row-reverse;
  gap: var(--gap-2xs);
}

.actionButton {
  width: 12px;
  height: 12px;
}

.startFormula {
  color: var(--color-light-graphic-tertiary-inverted);
}
