.optionContent {
  display: flex;
  gap: 1rem;
  align-items: center;
  white-space: nowrap;
}

.optionName {
  overflow: hidden;
  flex: 1;
  min-width: 40%;
  text-overflow: ellipsis;
}

.optionFirst {
  display: flex;
  gap: 6px;
  width: 40%;
}

.optionSecond {
  overflow: hidden;
  text-overflow: ellipsis;
}

.optionMarket {
  padding-left: var(--gap-2xs);
}
