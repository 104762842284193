@import url('@alfalab/core-components/vars/typography.css');

.contextMenuSettings {
  max-width: 270px;
}

.settingsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: var(--gap-s) var(--gap-xs);

  background-color: var(--color-light-bg-tertiary);
}

.settingsContent {
  display: flex;
  flex-direction: column;
  min-width: 270px;
  padding: var(--gap-m) var(--gap-xs);
}

.settingWrapper {
  cursor: pointer;
  user-select: none;

  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;

  margin: var(--gap-xs) 0;
  padding: var(--gap-2xs) 0;
}

.settingWrapper:first-of-type {
  margin-top: unset;
}

.hintTextForSwitch {
  margin-bottom: var(--gap-xs);
  color: var(--color-light-text-secondary);
}

.buttonToolbar {
  display: flex;
  gap: var(--gap-m);
  justify-content: flex-end;

  margin: var(--gap-xl) var(--gap-xs-neg) var(--gap-m-neg) var(--gap-xs-neg);
  padding: var(--gap-xs);

  background-color: var(--color-light-bg-tertiary);
}

.customButton span {
  @mixin paragraph_secondary_small;
}

.customButton {
  --size-xs-height: 24px;
}
