@import url('@alfalab/core-components/vars/typography.css');

.ticker {
  @mixin paragraph_primary_small;
}

.market {
  @mixin paragraph_primary_small;

  margin-left: var(--gap-2xs);
  color: var(--color-light-text-secondary);
}

.labelCenter {
  display: flex;
}

.linkedOrderIcon {
  display: flex;
  align-items: center;
  margin-left: var(--gap-xs);
}

.baseOrderId {
  @mixin paragraph_primary_small;

  margin-left: var(--gap-xs);
  color: #8d8d93;
}
