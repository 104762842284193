@import url('@alfalab/core-components/vars/typography.css');

.root {
  display: flex;
  flex-direction: column;

  min-width: 200px;
  padding-top: var(--gap-xs);
  padding-bottom: var(--gap-xs);

  background-color: var(--select-option-background);
  box-shadow: var(--select-options-list-box-shadow);
}

.checkContainer {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
}

.itemActions {
  margin-left: auto;
  opacity: 0;
}

.item:hover .itemActions {
  opacity: 1;
}

.group {
  display: grid;
}

.groupTitle {
  padding: var(--gap-2xs) var(--gap-xs);
}

.icon {
  width: 16px;
  height: 16px;
  color: var(--color-light-graphic-secondary);
}

.inlineFlex {
  display: inline-flex;
  align-items: center;
}

.tooltipContent {
  max-width: 200px;
}

.title {
  display: flex;
  padding: var(--gap-m) var(--gap-s);
}

.search {
  --form-control-s-min-height: var(--size-xs-height);

  @mixin paragraph_secondary_small;
}
