@import url('@alfalab/core-components/vars/typography.css');

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  @mixin paragraph_secondary_large;

  overflow: hidden;

  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-light-text-primary);

  background-color: var(--layout-color-background-1);
}

*::-webkit-scrollbar {
  width: 16px;
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--color-light-specialbg-secondary-transparent);
  background-clip: content-box;
  border: 6px solid transparent;
  border-radius: var(--border-radius-3xl);
}

*::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-light-specialbg-tertiary-transparent);
  border: 4px solid transparent;
}

*::-webkit-scrollbar-corner {
  background-color: transparent;
}

* {
  scrollbar-width: thin;
}

[alfa-portal-container] * {
  scrollbar-width: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
