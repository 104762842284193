.tooltipContent {
  display: flex;
  flex-direction: column;
  gap: var(--gap-xs);
  max-width: 200px;
}

.tooltip[data-popper-placement='top-start'] {
  left: -20px !important;
}

.tooltip[data-popper-placement='top-start'] .tooltipArrow::after {
  left: 14px !important;
}

.tooltip[data-popper-placement='top-end'] {
  right: -20px !important;
}

.tooltip[data-popper-placement='top-end'] .tooltipArrow::after {
  left: -26px !important;
}

.target {
  cursor: inherit;
}

.icon {
  cursor: pointer;
}

.tooltipHidden {
  display: none;
}
