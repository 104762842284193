.container {
  --color-light-bg-secondary: var(--color-light-graphic-quaternary);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-height: 100%;
  padding: var(--gap-7xl) var(--gap-4xl);

  text-align: center;
}

.icon {
  margin: 0 0 var(--gap-l);
}

.title {
  margin-bottom: var(--gap-m);
}

.content {
  margin-top: var(--gap-xl);
}
