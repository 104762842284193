.container {
  display: flex;
  flex-direction: column;
}

.statusContainer {
  display: flex;
  gap: var(--gap-xs);
}

.acceptionLink {
  display: flex;
  align-items: center;
  margin-top: var(--gap-xs);
}

.tooltipContent {
  --tooltip-max-width: 400px;
}

.tooltipArrowHide {
  display: none;
}

.infoLink {
  margin-top: var(--gap-xs);
}

.sentStatus {
  display: flex;
  gap: var(--gap-2xs);
  align-items: center;

  margin-top: var(--gap-xs);

  color: var(--text-color-secondary);
}
