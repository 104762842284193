.wrapper {
  overflow-x: auto;
  display: flex;
  width: 100%;
}

.table {
  border-spacing: 0;
  width: 100%;
  white-space: nowrap;
}

.head th {
  height: 32px;
  padding: 0 var(--gap-xs);
  padding-left: calc(2 * var(--gap-xs) + 2 * var(--gap-s));

  text-align: end;

  background-color: var(--dark-bg-secondary);
  border: 2px solid var(--dark-bg-primary);
  border-right: none;
  border-bottom: none;
}

.head th:first-child {
  padding-right: calc(2 * var(--gap-xs) + 2 * var(--gap-s));
  padding-left: var(--gap-xs);
  text-align: start;
  border-left: none;
}

.head th:hover {
  cursor: pointer;
  user-select: none;
}

.row {
  background-color: var(--color-light-bg-secondary-alpha-30);
}

.row:nth-child(2n) {
  background-color: var(--color-light-bg-primary);
}

.rowSmall {
  height: 50px;
}

.rowExtraSmall {
  height: 34px;
}

.rowExtraSmallMobile {
  height: 40px;
}

.cell {
  overflow: hidden;
  padding: 0 var(--gap-xs);
  text-align: end;
  text-overflow: ellipsis;
}

.cell:first-child {
  text-align: start;
}

td.textAlignStart,
th.textAlignStart {
  padding-right: calc(2 * var(--gap-xs) + 2 * var(--gap-s));
  padding-left: var(--gap-xs);
  text-align: start;
}

td.textAlignEnd,
th.textAlignEnd {
  padding-left: calc(2 * var(--gap-xs) + 2 * var(--gap-s));
  text-align: end;
}
