.root {
  --swiper-navigation-size: 12px;
  --swiper-navigation-color: var(--button-ghost-base-color);

  width: 100%;
  margin-bottom: var(--gap-3xs);
  background-color: var(--color-light-bg-info-muted);
}

:global(.swiper).root {
  z-index: 0;
}

.swiperWrapper {
  align-items: center;
}
