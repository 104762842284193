@import url('@alfalab/core-components/vars/typography.css');

.wrapper {
  display: flex;
  align-items: center;
}

.orderStatusFilters {
  border-radius: 100%;
}

.select {
  width: 240px;
  padding-top: 0;
}

.selectOrderBook {
  width: 180px;
}

.accountOption {
  @mixin accent_secondary_small;

  display: flex;
  justify-content: space-between;
  color: var(--button-secondary-color);
}

.accountOptionOrderBook {
  cursor: default;
}

.option {
  @mixin paragraph_secondary_large;

  position: relative;
  display: flex;
  gap: var(--gap-2xs);
  align-items: center;
}

.optionName {
  margin-left: var(--gap-xs);
}

.activeFilterButton {
  color: var(--dark-bg-primary);
  background-color: var(--dark-text-primary);
}

.activeFilterButton:hover {
  background-color: var(--tag-background-color-checked-hover);
}

.orderStatusFiltersSelect {
  width: 134px !important;
  min-width: 134px !important;
  padding: var(--gap-2xs) var(--gap-xs);

  /* stylelint-disable-next-line property-disallowed-list */
  font-size: 11px !important;
  /* stylelint-disable-next-line property-disallowed-list */
  line-height: 16px !important;

  background: var(--form-control-bg-color);
}

.positionTypeSelectPoppover {
  min-width: 140px;
}

/* stylelint-disable-next-line selector-class-pattern */
.PUModeSelectPoppover {
  min-width: 215px;
}

button.orderStatusFiltersFilterInSelect {
  display: flex;
  justify-content: flex-start;

  width: 95px !important;
  min-width: 95px !important;
  height: 24px !important;
  min-height: 24px;
  margin: var(--gap-2xs) 0;
  padding: var(--gap-2xs) var(--gap-m);

  /* stylelint-disable-next-line property-disallowed-list */
  font-size: 11px !important;
  /* stylelint-disable-next-line property-disallowed-list */
  line-height: 16px !important;
  text-overflow: ellipsis;
  white-space: nowrap;

  background: var(--tag-background-color-hover);
}

.count {
  opacity: 0.8;
}

.optionWithRequirements {
  margin-left: var(--gap-xl);
}

.requirementIcon {
  position: absolute;
  margin-left: var(--gap-xl-neg);
}

.accountSelectedIcon {
  margin-left: auto;
}

/* stylelint-disable-next-line selector-class-pattern */
.PUFiltersButton {
  margin-left: var(--gap-m);
}

/* stylelint-disable-next-line selector-class-pattern */
.PUFiltersButtonSmall {
  margin-left: var(--gap-xs);
}
