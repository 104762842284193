.groupTitle {
  margin-bottom: var(--gap-2xs);
  padding: var(--gap-2xs) var(--gap-xs);
  color: var(--dark-graphic-secondary);
}

.dropdownItem {
  padding: 0;
}

.divider {
  margin: var(--gap-xs) 0;
}
