.root {
  position: relative;
  display: grid;
  row-gap: var(--gap-4xl);
  justify-content: center;
}

.description {
  margin-bottom: var(--gap-m);
  color: var(--color-light-text-secondary);
  text-align: center;
}

.codeInput > div {
  display: flex;
  justify-content: center;
}
