.wrapper {
  position: relative;

  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 100%;
  padding: var(--gap-3xs) var(--gap-xs);

  text-overflow: ellipsis;
  white-space: nowrap;

  border-radius: var(--border-radius-s);
}

.wrapper:hover {
  cursor: pointer;
  color: var(--orderbook-count-text-hover);
  background: var(--layout-color-surface-2);
}

.wrapper:hover::after {
  content: '';

  position: absolute;
  right: 0;

  width: var(--gap-xs);
  height: 100%;

  background: linear-gradient(
    90deg,
    transparent 0%,
    var(--layout-color-surface-2) 100%
  );
}

.icon {
  min-width: 16px;
}

.count {
  margin-left: var(--gap-2xs);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chevron {
  min-width: 16px;
  margin-left: var(--gap-2xs);
}
