.rndTabset {
  display: flex !important;
  padding: var(--gap-3xs);
  background-color: transparent;
  border-radius: var(--border-radius-l);
}

.tabset {
  overflow: hidden;
  display: flex;
  flex: 1;
  flex-direction: column;

  background-color: var(--color-light-bg-primary);
  border: 1px solid var(--color-light-border-primary);
  border-radius: inherit;
}

.widgetContainer {
  scrollbar-color: var(--color-light-bg-secondary) var(--color-light-bg-primary);
  scrollbar-width: thin;

  overflow: hidden;
  display: flex;
  flex: 1;
  flex-direction: column;

  transition: opacity 0.3s ease-in-out;
}

.activeTabset {
  border: 1px solid var(--layout-color-background-accent-alpha-60);
}

.fullscreenTabSet {
  border: none;
  border-radius: 0;
}

.pinButton {
  overflow: hidden;
  display: inline-flex;

  width: 24px;
  max-width: 0;

  transition: max-width 0.2s ease-in-out;
}

.pinnedButton {
  max-width: 24px;
}

.tabset:hover .pinButton:not(.pinnedButton) {
  max-width: 24px;
}

.resizeHandleBottom {
  z-index: 3;
  bottom: 0 !important;
  height: 8px !important;
}

.resizeHandleTop {
  z-index: 3;
  top: 0 !important;
  height: 8px !important;
}

.resizeHandleLeft {
  z-index: 3;
  left: 0 !important;
  width: 8px !important;
}

.resizeHandleRight {
  z-index: 3;
  right: 0 !important;
  width: 8px !important;
}

.resizeHandleTopLeft {
  cursor: nwse-resize !important;

  z-index: 5;
  top: 0 !important;
  left: 0 !important;

  width: 12px !important;
  height: 12px !important;
}

.resizeHandleTopRight {
  cursor: nesw-resize !important;

  z-index: 5;
  top: 0 !important;
  right: 0 !important;

  width: 12px !important;
  height: 12px !important;
}

.resizeHandleBottomLeft {
  cursor: nesw-resize !important;

  z-index: 5;
  bottom: 0 !important;
  left: 0 !important;

  width: 16px !important;
  height: 16px !important;
}

.resizeHandleBottomRight {
  cursor: nwse-resize !important;

  z-index: 5;
  right: 0 !important;
  bottom: 0 !important;

  width: 16px !important;
  height: 16px !important;
}

.header {
  cursor: move;

  position: relative;

  display: flex;
  align-items: center;

  min-height: 24px;
  max-height: 24px;
  padding-right: var(--gap-xs);

  background-color: var(--layout-color-background-1);
  border-top-left-radius: var(--border-radius-l);
  border-top-right-radius: var(--border-radius-l);

  transition: all 0.3s ease-in-out;
}

.header.droppable {
  background-color: var(--layout-color-background-accent-alpha-40);
}

.tooltipContent {
  padding: var(--gap-2xs) var(--gap-xs) !important;
}

.tooltipArrow::after {
  display: none;
}

.tooltip {
  margin-top: var(--gap-xs-neg);
}

.dragHandle {
  display: flex;
  flex: 1;
  min-width: 24px;
  height: 100%;
}
