.paramsWrapper {
  display: flex;
  align-items: center;
  margin-right: var(--gap-m);
}

.paramsWrapper > * {
  margin-right: var(--gap-xs);
}

.tooltipTarget {
  position: absolute;
  top: 0;
  right: var(--gap-xs);
}

.tooltipContent > * {
  margin-right: 0;
}

.tooltipContent > *:not(:last-child) {
  margin-bottom: var(--gap-xs);
}
